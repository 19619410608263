@import "theming";
@import "fonts";

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $input-placeholder;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $input-placeholder;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $input-placeholder;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: $p-12;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.input-upload-default {
  @extend .button-border !optional;
  color: $secondary;
  background-color: #F5F5F5;
  border: $p-2 dashed #DCDEE0;
}

.input-upload-hover {
  @extend .button-border !optional;
  color: $secondary;
  background-color: #DBDEE1;
  border: $p-2 dashed #DCDEE0;
}

.input-upload-pressed {
  @extend .button-border !optional;
  background-color: #C8CED3;
  border: $p-2 dashed #DCDEE0;
}

.input-upload-uploading {
  @extend .button-border !optional;
  color: $secondary;
  background-color: #F5F5F5;
  border: $p-2 solid #DCDEE0;
}

.input-group-text {
  background-color: white;
  border: $p-2 solid white;
  border-right: none;
  box-shadow: 0 $p-2 $p-4 0 rgba(0, 0, 0, 0.12);
  @include standardBorderAnimation;
}

.hf-form-control {
  height: $p-44;
  border: 2px solid white;
  border-radius: 4px;
  box-shadow: $p-2 $p-2 $p-4 0 rgba(0, 0, 0, 0.12);
}

.hf-form-control:disabled {
  border: $p-2 solid $gray92 !important;
}

.hf-form-control.with-addon, .hf-form-control.with-addon:hover:not(:disabled) {
  border-left: none;
}

.hf-form-control:hover:not(:disabled) {
  border: $p-2 solid $input-hover;
}

.hf-form-control-with-asset {
  padding-right: $p-60;
}

.hf-form-control-with-icon {
  padding-right: 45px;
}

.hf-form-control:focus:not(.hf-invalid-field) {
  border: $p-2 solid $secondary !important;
}

.hf-form-control.with-addon:focus {
  border-left: none !important;
}

.hf-form-control-asset {
  position: absolute;
  z-index: 3;
  right: 13px;
  top: 10px;
}

.hf-form-control-icon {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 3;
  cursor: pointer;
  color: $gray80;
}

.input-group-text-focus {
  padding: 0.300rem .75rem;
  outline: none;
  border: 2px solid $secondary !important;
  border-right: none !important;
  @include standardBorderAnimation;
}

.input-group-text-hover {
  padding: 0.300rem .75rem;
  outline: none;
  border: $p-2 solid $input-hover;
  border-right: none !important;
  @include standardBorderAnimation;
}

.hf-form-control-hover {
  border: $p-2 solid $input-hover;
}

.input-group-text.addon-disabled {
  background-color: $gray92;
  border: $p-2 solid $gray92 !important;
}

.hf-checkbox-element, .hf-radio-element {
  padding: $p-10;

  user-select: none;

  .hf-checkbox-wrapper, .hf-radio-wrapper {
    margin-left: $p-44;

    input[type="checkbox"], input[type="radio"] {
      display: none;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 0 !important;
      cursor: pointer;
      @include fontStyle('regular-bold');
    }

    span {
      cursor: pointer;

      &:not(.prefix):before, &:not(.prefix):after {
        position: absolute;
        top: 0;
        margin: auto;
        content: '';
      }

      &.checkbox:before, &.radio:before {
        left: -35px;
        width: $p-20;
        height: $p-20;
        background-color: $gray100;
        border: 2px solid $gray72;
        box-sizing: border-box;
        border-radius: 50%;
        transition: border-color .2s;
      }

      &.checkbox:after, &.radio:after {
        top: 1px;
        left: -36px;
        font-family: 'Material Icons', 'serif';
        font-size: 23px;
        color: transparent;
        content: '\E86C';
        transition: color .2s;
      }

      &.checkbox:hover::before, &.radio:hover::before {
        border: 2px solid $input-hover;
      }

      &.radio {
        flex: 0 0 80%
      }

      & span.radio-details {
        display: block;
        font-weight: normal;
        @include fontStyle('common-style');
      }
    }

    input[type="checkbox"]:checked + label span.checkbox:after, input[type="radio"]:checked + label span.radio:after {
      color: $input-checked;
    }
  }
}

.hf-checkbox-element.is-selected, .hf-radio-element.is-selected {
  background-color: $gray92;
  border-radius: $p-4;
  transition: background-color .2s;
}

.hf-textarea {
  @include fontStyle('default-style');
  resize: none;
  display: block;
  width: 100%;
  height: $p-84;
  padding: $p-6 $p-12 $p-6 $p-12;
  box-sizing: border-box;
  font-family: Montserrat, serif;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border: $p-2 solid white;
  border-radius: $p-4;
  box-shadow: 0 $p-2 $p-4 0 rgba(0,0,0,0.12);
}

.hf-textarea::-ms-expand {
  border: 0;
  background-color: transparent;
}

.hf-textarea:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border: $p-2 solid #16324A !important;
  color: #495057;
  background-color: #fff;
}

.hf-textarea:hover:not(:disabled) {
  border: $p-2 solid #8C959E;
}

.hf-form-static-value {
  color: $gray24;
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 32px;
}


.form-control {
  display: block;
  width: 100%;
  height: 44px;
  padding: .375rem .75rem;
  @include fontStyle('default-style');
  line-height: 1.5;
  border: 1px solid $gray100;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
  color: #495057;
  background-color: #fff;
}

.form-control::-webkit-input-placeholder {
  opacity: 1;
  color: #6c757d;
}

.form-control::-moz-placeholder {
  opacity: 1;
  color: #6c757d;
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  color: #6c757d;
}

.form-control::-ms-input-placeholder {
  opacity: 1;
  color: #6c757d;
}

.form-control::placeholder {
  opacity: 1;
  color: #6c757d;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}
