@import "includes";

.desktop-only {
  @include mobile {
    display: none;
  }
}

.mobile-only {
  @include desktop {
    display: none;
  }
}

.ev-buttons-layout {
  display: flex;
  flex-direction: column;

  *:not(:last-of-type) {
    margin-bottom: 8px;
  }

  @include desktop {
    flex-direction: row;
    justify-content: center;

    *:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

.ev-sidepane-form {
  padding-top: 24px;
  padding-bottom: 24px;

  .ev-sidepane-form-section {
    .ev-sidepane-form-heading {
      margin-bottom: 16px;

      @extend %text-big-medium-grey24;
    }

    .ev-sidepane-form-content {
      display: flex;
      flex-wrap: wrap;

      margin-left: -32px;

      .ev-sidepane-form-column, .ev-sidepane-form-column-full-width {
        width: 100%;

        padding-left: 32px;
      }

      .ev-sidepane-form-column {
        @include desktop {
          width: 50%;
        }
      }
    }

    &.top-border {
      border-top: 1px solid $color-grey80;
      padding-top: 20px;
    }

    &.bottom-border {
      border-bottom: 1px solid $color-grey80;
      margin-bottom: 20px;
    }
  }
}

.ev-form-field {
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  & > label {
    margin-bottom: 8px;

    @extend %text-grey24;
    line-height: 16px;
  }

  // overrides other controls to have proper spacing:
  & > hf-selector-link {
    display: block;
    // margin-top: -8px;
    // margin-bottom: -12px;
  }

  & > hf-textarea {
    margin-bottom: -12px;
  }

  & > hf-checkbox {
    margin-bottom: 0;
  }

  & > hf-client-selector {
    label {
      margin-top: 0;
    }

    .hf-selector, .hf-selector-container {
      margin: 0 !important;
    }
  }

  & > hf-date-input {
    .hf-selector, .hf-selector-container {
      margin: 0 !important;
    }
  }

  &.two-column-checkbox-list > hfc-checkbox-list .hfc-checkbox-list-item {
    flex: 1 0 0;

    width: initial;
    min-width: 45%;
    max-width: calc(50% - 12px);

    padding: 12px 14px;
  }
}

.ev-form-error {
  @extend %text-small-medium-red;
}

.ev-sidepane-footer {
  hfc-button {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}
