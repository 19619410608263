@import "includes";
@import "theming";
@import "fonts";
@import "responsive";

$min-fixed-header-height: 76px;

.for-phone-show {
  display: none;
  @include for-phone-only {
    display: block;
  }
}

.for-desktop-show {
  display: none;
  @include for-desktop-only {
    display: block;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-space-between-layout {
  @extend %flex-space-between-layout;
}

.flex-end-layout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-space-around-baseline {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.flex-space-between-baseline {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.flex-space-between-stretch {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.align-items-center {
  @extend %align-items-center;
}

.align-items-end {
  align-items: end;
}

.margin-left-auto {
  margin-left: auto;
}

.table-page-header {
  padding: $p-16 $p-32;
  background: white;

  @include for-phone-only {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}

.header-crumbs-divider {
  color: $gray72;
  vertical-align: sub;
}

.table-page-container {
  display: flex;
  flex-flow: column;
  padding: $p-32;
  min-height: 100vh;

  @include for-phone-only {
    width: 100vw;
    padding: 0;
  }

  .table-tools {
    display: flex;

    hf-custom-text-field {
      flex: 0.5;
    }

    hf-selector-link {
      flex: 0.2;

      @include desktop {
        margin-left: 16px;
      }
    }

    .keep-right {
      margin-left: auto;
    }

    @include mobile {
      flex-direction: column;

      padding: 0 16px;
    }
  }

  // Obsolete
  .utils-area {
    @extend %flex-space-between-layout;

    @include for-phone-only {
      flex-flow: column;
      align-items: initial;
    }
  }

  .search-box-line {
    width: calc(5 / 12 * 100%);
    height: 46px;
    margin-bottom: 32px;

    @include for-phone-only {
      display: flex;
      padding: 0 16px;
      width: initial;
      margin-bottom: 0;

      .search-bar-wrap {
        width: 100%;
        margin-right: 12px;
      }
    }
  }

  .pagination-row {
    @extend %flex-space-between-layout;
    margin-top: $p-16;

    @include for-phone-only {
      flex-flow: column;

      everglades-paginator {
        margin-top: 8px;
      }
    }
  }
}

.sidepanes-anchor {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 99;
}

.sidepane-child-anchor {
  display: inline-block;
  vertical-align: top;
}

.hf-selector {
  margin-bottom: 12px;
  label {
    margin-top: 8px;
    @include fontStyle("common-style");
  }

  .hf-selector-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 44px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    background-color: $gray100;
    padding-right: 10px;

    @extend %text-overflow-ellipsis;
    transition: background-color 150ms;

    .title {
      @include fontStyle("regular-bold");
    }

    &:not(.readonly) {
      cursor: pointer;

      &:hover {
        background-color: $element-hover;
      }
    }

    &.hf-selector-has-value {
      height: auto;
      min-height: 44px;
      border: 2px solid $gray80;
      border-radius: 4px;

      &.invalid {
        border-color: $red;
      }

      .description {
        padding-top: 8px;
        @include fontStyle("common-style");
        @extend %text-overflow-ellipsis;
      }

      .description-extended {
        padding-top: 2px;
        @include fontStyle("common-style");
        @extend %text-overflow-ellipsis;
      }

      .hf-selector-label-area {
        :first-child:not(.material-icons) {
          padding-left: $p-16;
          color: $gray72;
        }
      }
    }

    &.hf-selector-black-color {
      margin-bottom: $p-12;
      .title {
        color: $gray24 !important;
      }
    }

    &.hf-selector-one-line {
      height: 44px;
    }
  }

  .hf-selector-label-area {
    display: flex;
    align-items: center;

    .material-icons {
      color: $primary;
      padding: 10px;
    }
  }

  &.hf-selector-label-only {
    margin-bottom: 0;
  }

  .hf-selector-content-area {
    padding: $p-12 $p-16;

    .title {
      @include fontStyle("regular-bold");
    }
  }

  .chevron-icon {
    font-size: $p-24;
    line-height: $p-24;
    color: $gray80;
  }
}

.mark-area {
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-bottom: 22px;

  &.passed {
    .material-icons {
      color: $green;
    }
  }

  &.canceled {
    .material-icons {
      color: $red;
    }
  }

  .material-icons {
    margin-right: 10px;
  }

  span {
    font-size: 14px;
  }
}

.file-attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 44px;
  border: 2px dashed $gray88;
  border-radius: 4px;
  padding: 0 16px;
  line-height: 44px;
  margin-bottom: 12px;
  transition: border-color 100ms;
  cursor: pointer;

  .file-with-name-container {
    display: flex;
    align-items: center;
  }

  div {
    color: $gray24;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    img {
      max-width: 50px;
      max-height: 50px;
      padding: 10px 10px 10px 0;
      box-sizing: content-box;
    }
  }

  .material-icons {
    color: $gray60;
  }

  &:hover {
    border-color: $gray72;
  }
}

.flex-phone-prefix {
  flex: 0 0 30%;

  @include for-phone-only {
    flex: 0 0 37%;
  }
}

.flex-phone {
  flex: 0 0 67%;

  @include for-phone-only {
    flex: 0 0 60%;
  }
}

.flex-header {
  @extend .flex-space-around-baseline;

  @include for-phone-only {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &--fixed {
    align-items: center;
    min-height: $min-fixed-header-height;
  }
}

.flex-title {
  flex: 1 0 0;

  @include for-phone-only {
    flex: 0 0 0;
  }
}

.user-photo {
  margin: -8px 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.introducer-logo {
  width: 73px;
  height: 27px;
}

.desktop-date-time {
  display: block !important;
  @include for-phone-only {
    display: none !important;
  }
}

.native-mobile-date-time {
  display: none !important;
  @include for-phone-only {
    display: block !important;
  }
}

@include for-desktop-only {
  .right-header hf-custom-button {
    margin-left: $p-16;
  }

  .left-header hf-custom-button {
    margin-right: $p-16;
  }
}

.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ev-details-heading {
  @extend %text-14-medium-grey24;
}

.ev-separator {
  border-top: 1px solid #c8cacc;

  margin-top: 18px;
  margin-bottom: 24px;
}

.ev-thumbnails-container {
  display: flex;
  flex-wrap: wrap;

  width: 100%;

  @include desktop {
    max-width: 704px;
    margin-left: -48px;
  }
}

.ev-details-container {
  @include desktop {
    display: flex;
    gap: 16px;
  }

  .ev-details-column {
    display: flex;
    flex-direction: column;

    @include desktop {
      width: 33.3%;
    }

    .ev-details-entry {
      display: flex;
      // align-items: center;

      padding: 6px 0;

      .ev-details-entry-label {
        width: 34%;

        @extend %text-small-medium-grey60;
      }

      .ev-details-entry-value {
        margin-left: 20px;
        width: 66%;

        @extend %text-medium-secondary;
      }
    }
  }
}
