@import "theming";

@mixin fontStyle($style) {
  @if $style == "regular-bold" {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $gray24;
  }

  @if $style == "secondary-bold-important" {
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 20px;
    color: $secondary !important;
  }

  @if $style == "small-heading-bold" {
    font-size: 14px;
    font-weight: 500;
    color: $secondary;
  }

  @if $style == "common-style" {
    font-size: 14px;
    color: $gray24;
    line-height: 16px;
  }

  @if $style == "default-style" {
    font-size: $primary-font-size;
    color: $gray24;
    line-height: 16px;
  }

  @if $style == "small-greyed-out-text" {
    font-size: 14px;
    color: $gray60;
  }

  @if $style == "small-greyed-out-text-2" {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $gray72;
  }
}

%text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
