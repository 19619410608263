@import "theming";
@import "responsive";

.shared-header-styles {
  @include for-phone-only {
    height: 44px;
    padding: 0 !important;
    justify-content: start !important;
    align-items: initial !important;

    h1 {
      display: block;
      font-size: 18px !important;
      line-height: 24px;
      margin-top: 12px !important;
      white-space: nowrap;
      overflow: initial !important;
    }
  }
}
.single-button-header {
  @extend .shared-header-styles;

  @include for-phone-only {
    hf-nav-button {
      button {
        color: grey;
        background-color: white !important;
        height: 44px;
        min-height: initial;

        i {
          color: grey;
        }
      }
    }
  }
}

.dashboard-header {
  @extend .shared-header-styles;

  h1{
    display: flex;

    .heading-part {
      margin-right: 5px;
    }
  }

  @include for-phone-only {
    hf-nav-button {
      display: none;
    }

    .heading-part {
      display: none;

      &:last-child {
        width: 13ch;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.button-wrap-shared {
  @include for-phone-only {
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100vw;
    padding: 12px;

    background: white;
    z-index: 13;

    hf-custom-button {
      display: block;
      width: 100%;
      margin-top: 8px;

      &:first-of-type {
        margin-top: 0px;
      }
    }

    button {
      width: 100%;

      .show-btn {
        width: initial;
        display: initial;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.single-button-wrap {
  @extend .button-wrap-shared;

  @include for-phone-only-bottom-padding;
}

.dashboard-header-buttons {
  @extend .button-wrap-shared;

  @include for-phone-only {
    transition: all ease-in-out 250ms;
    bottom: -330px;

    &:after {
      //position: absolute;
      //width: 100vw;
      //height: 100vh;
      //content: '';
      //background-color: rgba(54,58,61,0.72);
      //left: 0;
      //z-index: -1;
      //bottom: 100%;
    }
  }
}

.dashboard-headers-buttons-visible {
  @include for-phone-only {
    bottom: 0;

    &:after {
      position: absolute;
      content: '';
      width: 100vw;
      height: 200vh;
      background-color: rgba(100, 100, 100, 0.8);
      bottom: 100%;
      left: 0;
    }
  }
}

.menu-trigger-button {
  height: 44px;

  button {
    height: 44px;
    min-height: initial !important;
  }

  span {
    display: none;
  }
}

.table-page-container {
  @include for-phone-only {
    padding-top: 16px !important;
    padding-bottom: 88px !important;
  }
}

.pagination-area {
  @include for-phone-only {
    width: 100%;
  }
}
