$primary: #ED7B25;
$secondary: #16324A;
$tertiary: #A5C9DD;
$green: #2E994A;
$yellow: #EDCC26;
$red: #ED3A26;

$input-placeholder: #B1B4B8;
$input-hover: #8C959E;
$input-checked: #4F9753;
$input-bg-checked: #BCD6C0;

$element-hover: #DADDE0;
$element-active: #C9CED2;

$button-hover: #C0C7CD;

$gray0: #000000;
$gray24: #363A3D;
$gray40: #555;
$gray60: #939699;
$gray70: #b2b4b2;
$gray72: #B0B4B8;
$gray80: #C8CACC;
$gray88: #DCDEE0;
$gray92: #E6E8EB;
$gray96: #F2F4F5;
$gray100: #FFFFFF;

$message-notice-color: #DA4A35;

$button-alert-default: #ED3A26;
$button-alert-focus: rgba(22, 50, 74, 0.12);
$button-alert-visit: rgba(22, 50, 74, 0.2);

$button-primary-default: #ED7B25;
$button-primary-focus: rgba(22, 50, 74, 0.12);
$button-primary-visit: rgba(22, 50, 74, 0.2);

$font-label-weight: 500;
$font-text-weight: 400;

$primary-font-size: 16px;
$secondary-font-size: 14px;
$tertiary-font-size: 12px;

$p-1: 1px;
$p-2: 2px;
$p-4: 4px;
$p-5: 5px;
$p-6: 6px;
$p-8: 8px;
$p-10: 10px;
$p-12: 12px;
$p-13: 13px;
$p-14: 14px;
$p-16: 16px;
$p-20: 20px;
$p-22: 22px;
$p-24: 24px;
$p-28: 28px;
$p-30: 30px;
$p-32: 32px;
$p-36: 36px;
$p-44: 44px;
$p-52: 52px;
$p-60: 60px;
$p-68: 68px;
$p-72: 72px;
$p-84: 80px;
$p-80: 84px;
$p-88: 88px;
$p-92: 92px;
$p-96: 96px;
$p-100: 100px;
$p-106: 106px;

$sidenav-width: 244px;
$disabled-opacity: .52;

@mixin standardBoxShadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

@mixin standardUiAnimation {
  transition: all 0.15s ease-in-out;
}

@keyframes spinner-circular-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin standardBorderAnimation {
  transition: border-color 0.15s ease-in-out;
}
