.hf-imported-data {
  @import "includes";
  @import "everglades.includes";

  hf-custom-spinner {
    margin-top: 32px;
  }

  .container {
    padding: 16px;

    @include mobile {
      padding-bottom: 158px;
    }

    @include desktop {
      padding: 32px;
    }
  }

  .section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;

    @include desktop {
      margin-bottom: 16px;
    }

    .title {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 28px;
      @extend %text-big-medium;
    }

    .column {
      width: 100%;

      @include desktop {
        width: calc(33.33% - 32px);
        margin-right: 32px;

        hfc-review-section-item {
          width: 100%;
        }
      }
    }
  }

  .separator {
    margin: 20px 0 36px;
    border-bottom: 1px solid $color-grey80;

    @include for-phone-only {
      margin: 12px 0 28px;
    }
  }
}
