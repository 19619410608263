@import "theming";
@import "fonts";

.everglades-table {
  width: 100%;
  margin-top: $p-16;
  overflow: auto;
  background: white;

  a {
    text-decoration: none;

    @include for-phone-only {
      width: 100%;
      display: block;
    }
  }

  &.table-is-loading {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(0, 0, 0, 0.1);
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: $p-30;
      height: $p-30;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
      content: '';
      box-sizing: border-box;
      border: 7px solid white;
      border-top-color: #ddd;
      animation: spinner-circular-anim 400ms linear infinite;

      @include for-phone-only {
        top: 60px !important;
      }
    }
  }

  &.table-no-results {
    position: relative;
    height: $p-100;

    @include for-phone-only {
      height: 40px;
    }

    &:after {
      position: absolute;
      width: 100%;
      padding-top: $p-10;
      text-align: center;
      content: 'No results';
      color: $gray60;
    }
  }

  .table-paddings {
    padding-left: $p-8;
    padding-right: $p-8;
  }

  .everglades-table-headings {
    display: flex;

    background: #fff;

    @include for-phone-only {
      display: none;
    }

    div {
      display: flex;
      justify-content: center;
      flex-flow: column-reverse;

      @extend .table-paddings;
      @include fontStyle('small-heading-bold');

      min-height: $p-44;
      // box-shadow: inset 0 -2px 0 0 #F2F4F5;
      border-bottom: 2px solid $gray96;

      &.table-arrow-asc,
      &.table-arrow-desc {
        span {
          position: relative;

          &:after {
            margin-left: $p-5;
            content: 'keyboard_arrow_up';
            font-family: 'Material Icons';
            font-size: $p-13;
            vertical-align: middle;
          }
        }
      }

      &.table-arrow-desc {
        span {

          &:after {
            content: 'keyboard_arrow_down';
          }
        }
      }

      span.center {
        text-align: center;
      }
    }
  }

  .everglades-table-headings-sticky-wrapper {
    height: 44px;

    @include mobile {
      display: none;
    }

    &.isSticky {
      .everglades-table-headings {
        position: fixed;
        top: 0;

        overflow: hidden;
        z-index: 1;
      }

      animation-name: fadeIn;
      animation-duration: 250ms;
    }
  }

  .table-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include standardUiAnimation;

    @include for-phone-only {
      flex-flow: column;
      align-items: initial;
      border-bottom: solid 2px #f2f4f5;
    }

    div {
      @extend .table-paddings;
      min-height: $p-44;
      padding-top: $p-14;
      padding-bottom: $p-14;
      @include fontStyle('common-style');
      // justify-content: flex-end;
      display: flex;
      flex-flow: column-reverse;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include for-desktop-only {
	display: inline-block;
        height: 44px;
      }

      @include for-phone-only {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:before {
          width: 140px;
          display: inline-block;
          content: attr(data-mobileTitle);
          color: $gray60;
        }
      }

      &.avatar {
        @include for-desktop-only {
	  display: flex;
          justify-content: center;
        }

        align-items: center;
        padding: 0 8px;
      }

      &.center {
        display: flex;
        align-items: center;

        @include for-desktop-only {
          justify-content: center;
        }
      }

      span.tasksAll {
        display: flex;
        align-items: center;

        i {
          margin-left: 8px;
          font-size: 16px;
          color: $color-red;
        }
      }
    }

    &:hover {
      background: #F2F4F5 !important;
    }

    span.status-icon {
      @extend %text-overflow-ellipsis;

      height: $p-20;
      min-height: $p-20;
      padding: $p-4 $p-8;
      border-radius: $p-22;
      color: $gray100;
      font-size: $p-12;
      line-height: $p-16;
      text-align: center;

      &.not_assigned,
      &.awaiting_first_contact,
      &.contact_attempted,
      &.awaiting_qualification,
      &.partially_qualified,
      &.not_qualified {
        background-color: $gray72;
      }

      &.in_progress,
      &.qualified {
        background-color: $yellow;
      }

      &.prepared {
        background-color: $yellow;
      }

      &.submitted,
      &.offered {
        background-color: $primary;
      }

      &.completed,
      &.replacement_in_progress,
      &.replaced {
        background-color: $green;
      }
  
      &.not_proceeding {
        background-color: $red;
      }
    }
  }

  div {
    flex: 1 1 0px;
  }
}

.everglades-table .table-row div {
  hf-attachment-image {
    margin-top: -5px;
  }
}

.everglades-table.light {
  a:not(.total):nth-child(odd) .table-row {
    background: #f9f9f9;
  }

  a.total .table-row {
    margin-top: 24px;
    border-top: 2px solid #F2F4F5;

    @include mobile {
      display: none;
    }
  }

  .table-row div {
    &:before {
      @include mobile {
        width: 200px;
      }
    }
  }

  .align-right {
    @include desktop {
      text-align: right;
    }
  }
}

.fixed-table-container {
  @include for-desktop-only {
    padding: 16px 32px;
  }

  .table-page-container {
    min-height: 100% !important;
    padding: 16px;

    @include for-desktop-only {
      position: relative;
      padding: 0;
    }

    .everglades-table {
      .table-row {
        cursor: default;
      }

      a {
        &:last-child {
          .table-row {
            font-size: 14px;
            font-weight: 500;
            color: $secondary;

            div {
              border-top: 1px solid $gray96;
            }
          }
        }
      }

      &.two-frozen {
        @include for-desktop-only {
          .everglades-table-headings {
            div {
              min-width: 150px;

              &:nth-child(1) {
                position: absolute;
                z-index: 9;
                background: white;
              }

              &:nth-child(2) {
                position: absolute;
                left: 150px;
                min-width: 100px;
                z-index: 9;
                background: white;
                box-shadow: 4px 4px 4px 0px $gray92;
              }

              &:nth-child(3) {
                margin-left: 250px;
              }
            }
          }

          .table-row {
            &:hover {
              div {
                background-color: $gray96 !important;
                transition: all 0.15s ease-in-out;
              }
            }

            div {
              min-width: 150px;

              &:nth-child(1) {
                position: absolute;
                z-index: 9;
                background-color: white;
              }

              &:nth-child(2) {
                position: absolute;
                left: 150px;
                width: 100px;
                min-width: 100px;
                z-index: 9;
                background-color: white;
                box-shadow: 4px 4px 4px 0px $gray92;
              }

              &:nth-child(3) {
                margin-left: 250px;
              }
            }
          }

          a {
            &:last-child {
              .table-row {
                div {
                  &:nth-child(2) {
                    box-shadow: 4px 0px 0px 0px $gray92;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.everglades-table .everglades-table-headings,
.everglades-table .table-row {
  .center {
    @include desktop {
      align-items: center;
    }
  }

  .right {
    @include desktop {
      text-align: right;
    }
  }

  .width-60 {
    @include desktop {
      width: 60px;
      max-width: 60px;
      flex: 0 0 60px;
    }
  }

  .width-70 {
    @include desktop {
      width: 70px;
      max-width: 70px;
      flex: 0 0 70px;
    }
  }

  .width-80 {
    @include desktop {
      width: 80px;
      max-width: 80px;
      flex: 0 0 80px;
    }
  }

  .width-92 {
    @include desktop {
      width: 92px;
      max-width: 92px;
      flex: 0 0 92px;
    }
  }

  .width-100 {
    @include desktop {
      width: 100px;
      max-width: 100px;
      flex: 0 0 100px;
    }
  }

  .width-110 {
    @include desktop {
      width: 110px;
      max-width: 110px;
      flex: 0 0 110px;
    }
  }

  .width-120 {
    @include desktop {
      width: 120px;
      max-width: 120px;
      flex: 0 0 120px;
    }
  }

  .width-130 {
    @include desktop {
      width: 130px;
      max-width: 130px;
      flex: 0 0 130px;
    }
  }

  .width-160 {
    @include desktop {
      width: 160px;
      max-width: 160px;
      flex: 0 0 160px;
    }
  }

  .width-170 {
    @include desktop {
      width: 170px;
      max-width: 170px;
      flex: 0 0 170px;
    }
  }

  .width-250 {
    @include desktop {
      width: 250px;
      max-width: 250px;
      flex: 0 0 250px;
    }
  }

  .min-100 {
    @include desktop {
      min-width: 100px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
