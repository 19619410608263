$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
$material-design-icons-class-icons-generate-codepoints: false;
@import "~material-design-icons-iconfont/src/material-design-icons";

@import "~@angular/cdk/overlay-prebuilt.css";

@import "app/website/styles/layout.global";
@import "app/website/styles/button.global";
@import "app/website/styles/fixes.global";
@import "app/website/styles/forms.global";
@import "app/website/styles/sidepanes.global";
@import "app/website/styles/input.global";
@import "app/website/styles/mobile-styles.global";
@import "app/website/styles/tables.global";
@import "app/website/styles/validation.global";
@import "app/website/styles/layout-utils.global";
@import "app/website/styles/imported-data.global";
@import "app/website/styles/dashboard-layout.global";
@import "app/website/styles/dashboard-header.global";

@import "app/website/modules/login/login.global";

body,
button {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
}

body a {
  text-decoration: none;
}

.overlay-content {
  position: fixed;
  right: -1000px;
  top: 0;
  height: 100%;
}

.ui-overlay {
  background-color: #666666;
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 24px;
  color: $gray24;
  margin: 0;
}

.blurred-loading {
  filter: blur(4px);
}

@import "typography";
// Global typography classes
@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    .text#{$size-name}#{$weight-name} {
      @extend %text#{$size-name}#{$weight-name};
    }

    @each $color-name, $color in $typography-colors {
      .text#{$size-name}#{$weight-name}-#{$color-name} {
        @extend %text#{$size-name}#{$weight-name}-#{$color-name};
      }
    }
  }
}
