.ev-dashboard-header {
  position: relative;
  display: flex;
  align-items: center;

  background: #fff;

  @include desktop {
    padding: 0 32px;
    min-height: 76px;
  }

  hf-nav-button {
    button {
      color: grey;
      background-color: white !important;
      height: 44px;
      min-height: initial;

      i {
        color: grey;
      }
    }
  }

  .heading {
    flex: 1;
    display: flex;
    align-items: center;

    hf-custom-button i {
      color: #939699;
    }

    img {
      width: 32px;
      height: 32px;
    }

    h1 {
      @extend %text-18-medium-grey24;

      margin-left: 8px;
    }
  }

  hf-remortgage-status-badge {
    @include desktop {
      position: absolute;

      top: 88px;
      right: 32px;
    }
  }

  hf-mortgage-toolbar-with-menu {
    @include desktop {
      margin-left: 16px;
    }
  }

  hf-toolbar-button-container {
    margin-left: 8px;
    margin-right: 16px;

    @include desktop {
      margin-left: auto;
      margin-right: 0;
      margin-top: 24px;
    }
  }

  .status-wrapper {
    margin-right: 16px;
  }

  hf-custom-button[icon="more_horiz"] {
    @include desktop {
      margin-left: 16px;
    }
  }
}
