@import "theming";

.hf-validity-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 3;
  display: none;
}

.hf-valid-status, .hf-invalid-status {
  display: block;
}

.hf-valid-status .material-icons, .hf-invalid-status .material-icons {
  font-size: $primary-font-size;
}

.hf-valid-status .material-icons {
  color: $tertiary;
}

.hf-invalid-status .material-icons {
  color: $red;
}

.hf-invalid-field-addon {
  border: $p-2 solid !important;
  border-top-color: $red !important;
  border-bottom-color: $red !important;
  border-left-color: $red !important;
  border-right: none !important;
}

.hf-invalid-field {
  border: $p-2 solid !important;
  border-bottom-color: $red !important;
  border-top-color: $red !important;
  border-right-color: $red !important;
  border-left: none !important;
}

.hf-invalid-field:not(.with-addon) {
  border-left: $p-2 solid $red !important;
}

.hf-invalid-error {
  color: $red;
}
