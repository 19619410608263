@import "theming";

button.btn {
  min-height: 48px;
  padding: $p-12;
  font-family: Montserrat, serif;
  font-size: $primary-font-size;
  font-weight: 500;

  @include for-phone-only {
    min-height: 44px;
  }

  &[disabled], &.isLoading {
    /* #TODO To make the cursor on disabled event work would require 'require directive' link:
     https://stackoverflow.com/questions/46665625/how-to-combine-cursor-not-allowed-and-pointer-events-none */
    cursor: not-allowed;
    pointer-events: none;
    opacity: $disabled-opacity;
  }

  &.icon-only {
    min-width: 0;
  }

  &.sm-icon-only {
    min-width: 0;
    padding: $p-4;
  }

  &:hover {
    cursor: pointer;
  }
}

.sm-button-padding {
  padding: $p-8;
}

.mat-icon-right {
  flex-direction: row-reverse;
}

.center-right-icon-text {
  display: flex;
  flex: 0.4 1 auto;
}

.sm-center-right-icon-text {
  display: flex;
  flex: 0.45 1 auto;
}

.center-left-icon-text {
  display: flex;
  flex: 0.65 1 auto;
}

.sm-center-left-icon-text {
  display: flex;
  flex: 0.55 1 auto;
}

.padding-left-text {
  padding-left: $p-4;
}

.padding-right-text {
  padding-right: $p-4;
}

.center-text-left {
  display: flex;
  flex: 1;
}

.align-btn {
  display: flex;
  align-items: center;
  width: 100%;
}

.sm-align-btn {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: $secondary-font-size;

  i {
    font-size: $primary-font-size;
  }
}

.align-center {
  justify-content: space-between;
}

.align-btn-center {
  display: flex;
  align-items: center;
  width: 100%;

  span {
    flex: 1 1 auto;
  }
}

.sm-align-btn-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    font-size: $secondary-font-size;
  }
}

.button-border {
  border-radius: 4px;
  border: none;
}

.button-default {
  border-radius: 0;
  border: none;
}

.button-tab {
  border-radius: 4px 4px 0 0;
  border: none;
}

.button-plain-icon {
  border: none;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    background-color: #D1D5D9;
  }

  &:active {
    background-color: #BFC6CC;
  }
}

.button-alert {
  @extend .button-alert-default;
  color: $gray100;

  &:hover {
    @extend .button-alert-hover;
  }

  &:active {
    @extend .button-alert-active;
  }
}

.button-alert-default {
  @extend .button-border;
  color: $gray100;
  background-color: $button-alert-default;
}

.button-alert-hover {
  @extend .button-border;
  color: $gray100;
  background-color: #D3382A;
}

.button-alert-active {
  @extend .button-border;
  color: $gray100;
  background-color: #C1382D;
}

.ui-button-icon-right {
  text-align: left;
}

.button-primary button {
  @extend .button-primary-default;
  color: $gray100;

  &:hover {
    @extend .button-primary-hover;
  }

  &:active {
    @extend .button-primary-active;
  }

  &:focus {
    @extend .button-primary-hover;
  }
}

.button-primary-default {
  @extend .button-border;
  color: $gray100;
  background-color: $button-primary-default;
}

.button-primary-hover {
  @extend .button-border;
  color: $gray100;
  background-color: #D37129;
}

.button-primary-active {
  @extend .button-border;
  color: $gray100;
  background-color: #C16C2C;
}

.button-secondary {
  @extend .button-secondary-default;
  color: $gray100;

  &:hover {
    @extend .button-secondary-hover;
  }

  &:active {
    @extend .button-secondary-active;
  }

  &:focus {
    @extend .button-secondary-hover;
  }
}

.button-secondary-default {
  @extend .button-border;
  color: $secondary;
  background-color: #E4E6E8;
}

.button-secondary-hover {
  @extend .button-border;
  color: $secondary;
  background-color: #DBDEE1;
}

.button-secondary-active {
  @extend .button-border;
  color: $secondary;
  background-color: #C8CED3;
}

.button-red {
  color: #fff;
  background: $red;

  @extend .button-border;

  &:hover {
    background: #D33829;
  }

  &:active {
    background: #C1382C;
  }
}

.button-tertiary {
  @extend .button-tertiary-default;

  &:hover {
    @extend .button-tertiary-hover;
  }

  &:active {
    @extend .button-tertiary-active;
  }

  &:focus {
    @extend .button-tertiary-hover;
  }
}

.button-tertiary-default {
  color: $secondary;
  @extend .button-border;
  background-color: $gray100;
}

.button-tertiary-hover {
  color: $secondary;
  @extend .button-border;
  background-color: #DBDEE1;
}

.button-tertiary-active {
  color: $secondary;
  @extend .button-border;
  background-color: #C8CED3;
}

.button-navigation {
  @extend .button-navigation-default;

  &:hover {
    @extend .button-navigation-hover;
  }

  &:focus {
    @extend .button-navigation-focus;
  }

  &:active {
    @extend .button-navigation-active;
  }
}

.button-navigation-default {
  @extend .button-default;
  color: $secondary;
  background-color: $gray100;
}

.button-navigation-hover {
  @extend .button-default;
  color: $secondary;
  background-color: #F2F4F5;
  box-shadow: inset 4px 0 0 0 $secondary;
}

.button-navigation-focus {
  @extend .button-default;
  color: $secondary;
  background-color: $button-hover;
  box-shadow: inset 4px 0 0 0 $secondary;
}

.button-navigation-active {
  @extend .button-default;
  color: $secondary;
  background-color: #F2F4F5;
  box-shadow: inset 4px 0 0 0 $secondary;
}

.button-confirm {
  @extend .button-confirm-default;
  @extend .button-confirm-default;

  &:hover {
    @extend .button-confirm-hover;
  }

  &:focus {
    @extend .button-confirm-focus;
  }

  &:active {
    @extend .button-confirm-active;
  }
}

.button-confirm-default {
  @extend .button-border;
  color: $secondary;
  background-color: #E4E6E8;
}

.button-confirm-hover {
  @extend .button-border;
  color: $secondary;
  background-color: #DBDEE1;
}

.button-confirm-focus {
  @extend .button-border;
  color: $secondary;
  background-color: #F2F4F5;
}

.button-confirm-active {
  @extend .button-border;
  color: $secondary;
  background-color: #C8CED3;
}

.button-segmented {
  @extend .button-segmented-default;

  &:hover {
    @extend .button-segmented-hover;
  }

  &:focus {
    @extend .button-segmented-focus;
  }

  &:active {
    @extend .button-segmented-active;
  }
}

.button-segmented-default {
  @extend .button-border;
  color: $secondary;
  background-color: $gray100;
}

.button-segmented-hover {
  @extend .button-border;
  color: $secondary;
  background-color: #DBDEE1;
}

.button-segmented-focus {
  @extend .button-border;
  color: $secondary;
  background-color: #F2F4F5;
}

.button-segmented-active {
  @extend .button-border;
  color: $secondary;
  background-color: $gray100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.button-card {
  @extend .button-card-default;

  &:hover {
    @extend .button-card-hover;
  }

  &:focus {
    @extend .button-card-focus;
  }

  &:active {
    @extend .button-card-active;
  }
}

.button-card-default {
  @extend .button-border;
  color: $gray100;
  background-color: $tertiary;
}

.button-card-hover {
  @extend .button-border;
  color: $gray100;
  background-color: #93B6CB;
}

.button-card-focus {
  @extend .button-border;
  color: $gray100;
  background-color: #88AABF;
}

.button-card-active {
  @extend .button-border;
  color: $gray100;
  background-color: #C8CED3;
}

.button-tab {
  @extend .button-tab-default;

  &:hover {
    @extend .button-tab-hover;
  }

  &:focus {
    @extend .button-tab-focus;
  }

  &:active {
    @extend .button-tab-active;
  }
}

.button-tab-default {
  @extend .button-tab;
  color: $secondary;
  background-color: $gray100;
  box-shadow: inset 0 -2px 0 0 $secondary;
}

.button-tab-hover {
  @extend .button-tab;
  color: $secondary;
  background-color: #DBDEE1;
  box-shadow: inset 0 -2px 0 0 $secondary;
}

.button-tab-focus {
  @extend .button-tab;
  color: $secondary;
  background-color: #C8CED3;
  box-shadow: inset 0 -2px 0 0 $secondary;
}

.button-tab-active {
  @extend .button-tab;
  color: $secondary;
  background-color: #F5F5F5;
  box-shadow: inset 0 2px 0 0 $secondary, inset -2px 0 0 0 $secondary, inset -2px 0 0 0 $secondary, inset 2px 0 0 0 $secondary;
}

.cancel-button {
  margin-left: 16px;
}

.delete-button {
  color: $gray60;
}
