@import "theming";
@import "fonts";
@import "responsive";
@import "includes";

$sidepaneWidth: 450px;
$sidepaneWidth-2cols: 788px;

.sidepane-content {
  width: $sidepaneWidth;
  height: 100%;

  @include for-phone-only {
    width: 100vw;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.sidepane-content.cols-2, .cols-2 > .sidepane-content {
  width: $sidepaneWidth-2cols;

  @include for-phone-only {
    width: 100vw;
  }
}

.template-header {
  width: 100%;
  background-color: $gray100;
  height: 76px;
  padding: 0 $p-32;

  hf-custom-button i {
    color: $gray60;
  }

  @include for-phone-only {
    position: sticky;
    top: 0;
    height: 44px;
    z-index: 9;

    h1 {
      font-size: 18px;
    }
  }
}

.template-main {
  width: 100%;
  height: calc(100% - 152px);
  padding: 0 $p-32;

  overflow-y: auto;
  background-color: $gray96;

  opacity: 1;
  transition: opacity 200ms;
  -webkit-overflow-scrolling: touch;

  overscroll-behavior: none;

  > form {
    padding-top: $p-24;

    label {
      @include fontStyle('common-style');
      width: 100%;
    }
  }

  @include for-phone-only {
    height: initial;
    // min-height: calc(100% - 118px);
    min-height: calc(100% - 112px);
  }
}

.template-footer {
  width: 100%;
  height: 76px;
  padding: 0 $p-32;
  background-color: $gray100;
  display: flex;
  justify-content: flex-start;
  align-items: center;


  @include for-phone-only-bottom-padding {
    position: sticky;
    bottom: 0;
    height: unset;
    padding: 12px 12px 12px 12px;
    width: unset;
  }

  &.button-spacing {
    hfc-button {
      flex: 1 1 0px;

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      @include desktop {
        flex: initial;
      }
    }
  }
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.4);
  z-index: 500;
  right: 100%;
  transition: all 300ms;
}

// Client components (hfc-*) overrides for Everglades App
.ev-sidepane-form hfc-info-box-container {
  margin: 0 0 12px -32px !important;
}

hfc-text-input {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

hfc-info-box {
  margin: 0 0 12px 32px !important;

  min-width: 90% !important;
  max-width: calc(100% - 32px) !important;

  @include desktop {
    min-width: 45% !important;
    max-width: calc(50% - 32px) !important;
  }
}

.info-box-container-full-width hfc-info-box {
  @include desktop {
    // min-width: 95% !important;
    // ^ commented because it overflows form field div
    max-width: calc(100% - 12px) !important;
  }
}

hfc-add-button {
  margin: 0 0 12px 32px !important;

  min-width: 90% !important;
  max-width: calc(100% - 32px) !important;

  @include desktop {
    min-width: 45% !important;
    max-width: calc(50% - 32px) !important;
  }
}

.info-box-container-full-width hfc-add-button {
  @include desktop {
    // min-width: 95% !important;
    // ^ commented because it overflows form field div
    max-width: calc(100% - 12px) !important;
  }
}
