@mixin for-phone-only {
  @media (max-width: 767px), screen and (max-width: 900px) and (max-height: 430px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin for-tablet-phone {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin for-phone-only-bottom-padding {
  @include for-phone-only {
    @content;



    // because iOS ignores the next rule in PWA installed mode
    @media all and (display-mode: standalone) {
      @supports (-webkit-touch-callout: none) {
        padding-bottom: 34px;
      }
    }

    // https://webkit.org/blog/7929/designing-websites-for-iphone-x/
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }
}

%flex-space-between-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%align-items-center {
  display: flex;
  align-items: center;
}
