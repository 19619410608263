@import "includes";

.login-page {
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    align-items: center;

    @extend %text-24-medium-grey24;
    line-height: 28px;

    margin-bottom: 8px;

    i {
      color: $color-grey60;

      margin-right: 20px;
      margin-left: 10px;

      cursor: pointer;
    }
  }

  .hint {
    @extend %text-14-grey60;
    line-height: 16px;

    margin-bottom: 24px;
  }

  .buttons {
    margin-top: 32px;
  }

  .error {
    margin: 16px 0;
    @extend %text-14-red;
  }

  .link-button {
    @extend %text-14-medium-grey24;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .loading {
    color: $color-grey60 !important;
  }

  .red {
    color: $color-red !important;
  }
}

