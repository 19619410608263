@import "theming";

/* global styles to fix / amend css problems */

body {
  @include for-phone-only() {
    background-color: $gray96;
  }
}

// overwrite PrimeNG font with app font
body .ui-widget {
  font-family: Montserrat, serif !important;
}

[type=reset], [type=submit], button, html [type=button] {
  appearance: none !important;
}

.form-control:disabled {
  background-color: $gray92;
}

hfc-toast {
  z-index: 501 !important;
}

// overrides of client component styles
hfc-text-input {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}
