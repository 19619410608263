@import "includes";

.ev-dashboard-box {
  display: flex;
  flex-direction: column;

  padding: 16px;

  .heading {
    @extend %text-14-medium-grey60;
  }

  .item {
    display: flex;

    &.center {
      align-items: center;
    }

    &:not(:first-child) {
      margin-top: 12px;
    }

    .label {
      width: 112px;

      @extend %text-12-medium-grey60;
      line-height: 16px;
    }

    .value {
      flex: 1;
      margin-left: 12px;

      @extend %text-14-medium-secondary;
      line-height: 16px;

      overflow-wrap: break-word;
      overflow: hidden;

      &.pre {
        white-space: pre-line;
      }
    }
  }

  .separator {
    border-top: 1px solid #E6E8EB;
  }
}

.ev-dashboard-box-attachments {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  padding-top: 16px;
  padding-bottom: 16px;

  hfc-document-thumbnail {
    margin: 0;
    padding: 0;
  }
}
